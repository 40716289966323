import React from 'react'
import ConstructorSite from '../../components/complex/ConstructorSite'
import MobileBlock from '../../components/complex/MobileBlock'

const MainPage = () => {
  return (
    <div className='wrapper'>
        <div className='container'>
            <ConstructorSite/>
            <MobileBlock/>
        </div>
    </div>
  )
}

export default MainPage