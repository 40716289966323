import React, { useState } from 'react'
import img from "../../images/design.png"
import Modal from './Modal'

const DesignSelect = ({
    setConstructorItem, 
    siteType,
    designType,
    setDesignType
}) => {
    const [show, setShow] = useState();
    const [error, setError] = useState();

    const handleNext = () => {
        if(designType){
            setConstructorItem(3)
        }else{
            setError(true)
        }
    }

    const handleSelectDesign = (value) => {
        setDesignType(value);
        setError(false)
    }

  return (
    <>
        <div>
            <h3>Стоит выбрать решение которое подойдет вам лучше всего</h3>
            <p>Все зависит для каких задач вам необходим сайт</p>
        </div>
        <div>
            <div className='design-types'>
                <div className={designType=== 'light' ? 'design-types-item active' : 'design-types-item'} onClick={() => handleSelectDesign('light')}>
                    <div className='img-icon'>
                        <img src={img} alt="" />
                        <div className='text' onClick={() => setShow(true)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_362_3482" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                                <rect width="36" height="36" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_362_3482)">
                                <path d="M4.5 28.5C3.675 28.5 2.96875 28.2063 2.38125 27.6188C1.79375 27.0312 1.5 26.325 1.5 25.5V10.5C1.5 9.675 1.79375 8.96875 2.38125 8.38125C2.96875 7.79375 3.675 7.5 4.5 7.5H19.5C20.325 7.5 21.0312 7.79375 21.6188 8.38125C22.2063 8.96875 22.5 9.675 22.5 10.5V25.5C22.5 26.325 22.2063 27.0312 21.6188 27.6188C21.0312 28.2063 20.325 28.5 19.5 28.5H4.5ZM27 16.5C26.575 16.5 26.2188 16.3562 25.9313 16.0688C25.6438 15.7812 25.5 15.425 25.5 15V9C25.5 8.575 25.6438 8.21875 25.9313 7.93125C26.2188 7.64375 26.575 7.5 27 7.5H33C33.425 7.5 33.7812 7.64375 34.0687 7.93125C34.3563 8.21875 34.5 8.575 34.5 9V15C34.5 15.425 34.3563 15.7812 34.0687 16.0688C33.7812 16.3562 33.425 16.5 33 16.5H27ZM28.5 13.5H31.5V10.5H28.5V13.5ZM4.5 25.5H19.5V10.5H4.5V25.5ZM6 22.5H18L14.0625 17.25L11.25 21L9.1875 18.2625L6 22.5ZM27 28.5C26.575 28.5 26.2188 28.3563 25.9313 28.0688C25.6438 27.7812 25.5 27.425 25.5 27V21C25.5 20.575 25.6438 20.2188 25.9313 19.9313C26.2188 19.6438 26.575 19.5 27 19.5H33C33.425 19.5 33.7812 19.6438 34.0687 19.9313C34.3563 20.2188 34.5 20.575 34.5 21V27C34.5 27.425 34.3563 27.7812 34.0687 28.0688C33.7812 28.3563 33.425 28.5 33 28.5H27ZM28.5 25.5H31.5V22.5H28.5V25.5Z" fill="#FF4242"/>
                                </g>
                            </svg>
                            <span>Предпросмотр</span>
                        </div>
                    </div>
                    <div className='block-padding'>
                        <h3>Light</h3>
                        <p>Простой шаблон с карточками товаров и акциями. Быстрое оформление заказов и ничего лишнего. Идеальное решение если у вас небольшой ассортимент</p>
                    </div>
                </div>
                <div className={designType=== 'market' ? 'design-types-item active' : 'design-types-item'} onClick={() => handleSelectDesign('market')}>
                    <div className='img-icon'>
                        <img src={img} alt="" />
                        <div className='text' onClick={() => setShow(true)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_362_3482" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                                <rect width="36" height="36" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_362_3482)">
                                <path d="M4.5 28.5C3.675 28.5 2.96875 28.2063 2.38125 27.6188C1.79375 27.0312 1.5 26.325 1.5 25.5V10.5C1.5 9.675 1.79375 8.96875 2.38125 8.38125C2.96875 7.79375 3.675 7.5 4.5 7.5H19.5C20.325 7.5 21.0312 7.79375 21.6188 8.38125C22.2063 8.96875 22.5 9.675 22.5 10.5V25.5C22.5 26.325 22.2063 27.0312 21.6188 27.6188C21.0312 28.2063 20.325 28.5 19.5 28.5H4.5ZM27 16.5C26.575 16.5 26.2188 16.3562 25.9313 16.0688C25.6438 15.7812 25.5 15.425 25.5 15V9C25.5 8.575 25.6438 8.21875 25.9313 7.93125C26.2188 7.64375 26.575 7.5 27 7.5H33C33.425 7.5 33.7812 7.64375 34.0687 7.93125C34.3563 8.21875 34.5 8.575 34.5 9V15C34.5 15.425 34.3563 15.7812 34.0687 16.0688C33.7812 16.3562 33.425 16.5 33 16.5H27ZM28.5 13.5H31.5V10.5H28.5V13.5ZM4.5 25.5H19.5V10.5H4.5V25.5ZM6 22.5H18L14.0625 17.25L11.25 21L9.1875 18.2625L6 22.5ZM27 28.5C26.575 28.5 26.2188 28.3563 25.9313 28.0688C25.6438 27.7812 25.5 27.425 25.5 27V21C25.5 20.575 25.6438 20.2188 25.9313 19.9313C26.2188 19.6438 26.575 19.5 27 19.5H33C33.425 19.5 33.7812 19.6438 34.0687 19.9313C34.3563 20.2188 34.5 20.575 34.5 21V27C34.5 27.425 34.3563 27.7812 34.0687 28.0688C33.7812 28.3563 33.425 28.5 33 28.5H27ZM28.5 25.5H31.5V22.5H28.5V25.5Z" fill="#FF4242"/>
                                </g>
                            </svg>
                            <span>Предпросмотр</span>
                        </div>
                    </div>
                    <div className='block-padding'>
                        <h3>Market</h3>
                        <p>Комплексное решение для вас и вашего бизнеса. Каталог товаров, баннеры, новости, типовые страницы. Подходит для тех кому нужна площадка с возможностью SEO продвижения</p>
                    </div>
                </div>
                <div className={designType=== 'corporate' ? 'design-types-item active' : 'design-types-item'} onClick={() => handleSelectDesign('corporate')}>
                    <div className='img-icon'>
                        <img src={img} alt="" />
                        <div className='text' onClick={() => setShow(true)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_362_3482" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                                <rect width="36" height="36" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_362_3482)">
                                <path d="M4.5 28.5C3.675 28.5 2.96875 28.2063 2.38125 27.6188C1.79375 27.0312 1.5 26.325 1.5 25.5V10.5C1.5 9.675 1.79375 8.96875 2.38125 8.38125C2.96875 7.79375 3.675 7.5 4.5 7.5H19.5C20.325 7.5 21.0312 7.79375 21.6188 8.38125C22.2063 8.96875 22.5 9.675 22.5 10.5V25.5C22.5 26.325 22.2063 27.0312 21.6188 27.6188C21.0312 28.2063 20.325 28.5 19.5 28.5H4.5ZM27 16.5C26.575 16.5 26.2188 16.3562 25.9313 16.0688C25.6438 15.7812 25.5 15.425 25.5 15V9C25.5 8.575 25.6438 8.21875 25.9313 7.93125C26.2188 7.64375 26.575 7.5 27 7.5H33C33.425 7.5 33.7812 7.64375 34.0687 7.93125C34.3563 8.21875 34.5 8.575 34.5 9V15C34.5 15.425 34.3563 15.7812 34.0687 16.0688C33.7812 16.3562 33.425 16.5 33 16.5H27ZM28.5 13.5H31.5V10.5H28.5V13.5ZM4.5 25.5H19.5V10.5H4.5V25.5ZM6 22.5H18L14.0625 17.25L11.25 21L9.1875 18.2625L6 22.5ZM27 28.5C26.575 28.5 26.2188 28.3563 25.9313 28.0688C25.6438 27.7812 25.5 27.425 25.5 27V21C25.5 20.575 25.6438 20.2188 25.9313 19.9313C26.2188 19.6438 26.575 19.5 27 19.5H33C33.425 19.5 33.7812 19.6438 34.0687 19.9313C34.3563 20.2188 34.5 20.575 34.5 21V27C34.5 27.425 34.3563 27.7812 34.0687 28.0688C33.7812 28.3563 33.425 28.5 33 28.5H27ZM28.5 25.5H31.5V22.5H28.5V25.5Z" fill="#FF4242"/>
                                </g>
                            </svg>
                            <span>Предпросмотр</span>
                        </div>
                    </div>
                    <div className='block-padding'>
                        <h3>Corporate</h3>
                        <p>Простой шаблон с карточками товаров и акциями. Быстрое оформление заказов и ничего лишнего. Идеальное решение если у вас небольшой ассортимент</p>
                    </div>
                </div>
            </div>
            {error && <div className='error-text'>Пожалуйста, выберите решение для вашего сайта.</div>}
        </div>
        <div className='buttons-flex'>
            <div className='border-btn mx-wd' onClick={() => setConstructorItem(1)}>Назад</div>
            <div className='main-btn mx-wd' onClick={handleNext}>Далее</div>
        </div>
        <Modal show={show} setShow={setShow}/>
    </>
  )
}

export default DesignSelect