import React, { useState } from 'react'
import SampleType from './SampleType'
import DesignSelect from './DesignSelect'
import Branding from './Branding'
import Loading from '../simple/Loading'

const ConstructorSite = () => {
    const [constructorItem, setConstructorItem] = useState(1);
    const [siteType, setSiteType] = useState();
    const [designType, setDesignType] = useState();

  return (
    <div className='constructor-wrapper mob-none'>
        <h1>Давайте начнем сборку вашего сайта</h1>
        <div className='slider-steps'>
            <div className='slider-step active'>
                <div className='slider-step-pin'>1</div>
                <span>Выбор направления</span>
            </div>
            <div className={(constructorItem === 2 || constructorItem === 3 || constructorItem === 4) ? 'slider-step active' : 'slider-step'}>
                <div className='slider-step-pin'>2</div>
                <span>Выбор решения</span>
            </div>
            <div className={(constructorItem === 3 || constructorItem === 4) ? 'slider-step active' : 'slider-step'}>
                <div className='slider-step-pin'>3</div>
                <span>Брендирование</span>
            </div>
            <div className={constructorItem === 4 ? 'slider-step active' : 'slider-step'}>
                <div className='slider-step-pin'>4</div>
                <span>Запуск сайта</span>
            </div>
        </div>
        {constructorItem === 1 ?
            <SampleType setConstructorItem={setConstructorItem} siteType={siteType} setSiteType={setSiteType}/> :
        constructorItem === 2 ?
            <DesignSelect setConstructorItem={setConstructorItem} siteType={siteType} setDesignType={setDesignType} designType={designType}/> :
        constructorItem === 3 ?
            <Branding setConstructorItem={setConstructorItem}/> :
        constructorItem === 4 ?
            <Loading/> :
        <></>
        }
    </div>
  )
}

export default ConstructorSite