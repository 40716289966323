import React, { useState } from 'react'
import ColorModal from './ColorModal';

const SelectColor = ({selectColor, setSelectedColor}) => {
    const [show, setShow] = useState();

    const [colors, setColors] = useState([
        {id: 1, value: '#389ECA',},
        {id: 2, value: '#E97F51',},
        {id: 3, value: '#00AC6D',},
        {id: 4, value: '#3F4898',},
        {id: 5, value: '#5B4A90',},
        {id: 6, value: '#20B137',},
        {id: 7, value: '#00BCAA',},
    ])
    
  return (
    <div className='items-flex mb-15'>
        Фирменный цвет
        <div className='colors'>
            {colors?.map((card, index) =>
                <div key={card.id} className={selectColor === card.value ? 'color-item active' : 'color-item'} 
                    style={{background: card.value}} onClick={() => setSelectedColor(card.value)}></div>
            )}
            <div className='color-item-pin' onClick={() => setShow(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <mask id="mask0_359_3206" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_359_3206)">
                    <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" fill="#FF4242"/>
                    </g>
                </svg>
            </div>
            {/*<span className='accent-color'>Определить с логотипа</span>*/}
        </div>
        <ColorModal show={show} setShow={setShow} colors={colors} setColors={setColors}/>
    </div>
  )
}

export default SelectColor