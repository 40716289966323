import React, { useState } from 'react'
import { PatternFormat } from 'react-number-format';
import SelectColor from '../simple/SelectColor';

const Branding = ({
    setConstructorItem
}) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const [imgLogo, setImgLogo] = useState();

    const [selectColor, setSelectedColor] = useState();

    const [mainPhone, setMainPhone] = useState('');
    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');

    const [error, setError] = useState();

    const [nameError, setNameError] = useState();
    const [phoneError, setPhoneError] = useState();
    const [phone2Error, setPhone2Error] = useState();
    const [fullnameError, setFullnameError] = useState();

    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            setImgLogo(e.target.result);
          };
          reader.readAsDataURL(file);
        }
    }

    const handleSubmit = () => {
        if(!name){
            setNameError(true)
        }
        if(!phone){
            setPhoneError(true)
        }
        if(!fullName){
            setFullnameError(true)
        }
        if(!mainPhone){
            setPhone2Error(true)
        }
        if(!name || !phone || !fullName || !mainPhone){
            setError(true)
        }else{
            setConstructorItem(4) 
        }
    }

  return (
    <>
        <div>
            <h3>Информация о компании</h3>
            <p>Заполните поля ниже, чтобы персонализировать сайт под себя</p>
        </div>
        <div>
            <div className='items-flex mb-15'>
                Название компании*
                <input type="text" 
                    className={nameError ? 'input error' : 'input'} 
                    value={name} 
                    onChange={e => {
                        setName(e.target.value);
                    }}
                    onFocus={() => {
                        setNameError(false)
                    }}
                    placeholder='Введите название'
                />
            </div>
            {/*<div className='items-flex mb-15'>
                Адрес
                <input type="text" 
                    className='input'
                    value={address} 
                    onChange={e => {
                        setAddress(e.target.value);
                    }}
                    placeholder='Введите адрес'
                />
            </div>*/}
            <div className='items-flex mb-15'>
                Номера телефона*
                <PatternFormat 
                    format="+7 (###) #### ###" 
                    allowEmptyFormatting 
                    mask="_"
                    className={phoneError ? 'input error' : 'input'}
                    value={phone}
                    onChange={e=>{
                        setPhone(e.target.value);
                    }}
                    onFocus={() => {
                        setPhoneError(false);
                    }}
                />
            </div>
            <div className='items-flex mb-15'>
                <div>
                    <div>Логотип*</div>
                    <span className='fs-14'>Загрузите изображение в формате .png </span>
                </div>
                <div className='d-flex'>
                    <div className='upload'>
                        <input className='upload-input' type="file" onChange={handleChange} accept="image/jpeg, image/png, image/svg, image/webp, image/jpg"/>
                        {imgLogo ?
                            <div>
                                <img src={imgLogo}/>
                                <div className='img-hover'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="32" viewBox="0 0 35 32" fill="none">
                                    <path d="M17.5 23.4213L6.875 13.9147L9.85 11.1578L15.375 16.1012V0.605469H19.625V16.1012L25.15 11.1578L28.125 13.9147L17.5 23.4213ZM4.75 31.0265C3.58125 31.0265 2.58073 30.6542 1.74844 29.9095C0.916146 29.1648 0.5 28.2696 0.5 27.2239V21.5199H4.75V27.2239H30.25V21.5199H34.5V27.2239C34.5 28.2696 34.0839 29.1648 33.2516 29.9095C32.4193 30.6542 31.4188 31.0265 30.25 31.0265H4.75Z" fill="#FF4242"/>
                                </svg>
                                </div>
                            </div>
                            :
                            <div className='upload-content'>Загрузить</div>
                        }
                    </div>
                    {/*<span className='accent-color'>Сгенерировать</span>*/}
                </div>
            </div>
            <SelectColor setSelectedColor={setSelectedColor} selectColor={selectColor}/>
        </div>
        <div>
            <div>
                <h3>Информация об администраторе</h3>
                <p className='mb-15'>Контактное лицо для связи*</p>
                <div className='items-flex'>
                    <input type="text" className={fullnameError ? 'input error' : 'input'} value={fullName} 
                        onChange={e => {
                            setFullName(e.target.value);
                        }}
                        onFocus={() => {
                            setFullnameError(false);
                        }}
                        placeholder='ФИО'
                    />
                    <PatternFormat 
                        format="+7 (###) #### ###" 
                        allowEmptyFormatting 
                        mask="_"
                        className={phone2Error ? 'input error' : 'input'}
                        value={mainPhone}
                        onChange={e=>{
                            setMainPhone(e.target.value);
                        }}
                        onFocus={() => {
                            setPhone2Error(false);
                        }}
                    />
                </div>
            </div>
            {error && <div className='error-text'>Пожалуйста, заполните обязательные поля.</div>}
        </div>
        <div className='buttons-flex'>
            <div className='border-btn mx-wd' onClick={() => setConstructorItem(2)}>Назад</div>
            <div className='main-btn mx-wd' onClick={handleSubmit}>Создать свой сайт</div>
        </div>
    </>
  )
}

export default Branding