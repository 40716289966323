import { Component } from "react";
import MainPage from "../pages/main/MainPage";
import { ADMIN_DESIGN_CREATE_PAGE, ADMIN_DESIGN_PAGE, ADMIN_DIRECTION_CREATE_PAGE, ADMIN_DIRECTION_EDIT_PAGE, ADMIN_DIRECTION_PAGE, ADMIN_PAGE, MAIN_PAGE_ROUTE } from "./Constants";
import Direction from "../pages/admin/direction/Direction";
import SiteDesign from "../pages/admin/siteDesign/SiteDesign";
import DirectionCreate from "../pages/admin/direction/DirectionCreate";
import SiteDesignCreate from "../pages/admin/siteDesign/SiteDesignCreate";
import DirectionEdit from "../pages/admin/direction/DirectionEdit";

export const publicRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: '/',
        Component: MainPage
    },
]
export const privateRoutes = [
    {
        path: ADMIN_PAGE,
        Component: Direction
    },
    {
        path: ADMIN_DESIGN_PAGE,
        Component: SiteDesign
    },
    {
        path: ADMIN_DIRECTION_PAGE,
        Component: Direction
    },
    {
        path: ADMIN_DIRECTION_CREATE_PAGE,
        Component: DirectionCreate
    },
    {
        path: ADMIN_DESIGN_CREATE_PAGE,
        Component: SiteDesignCreate
    },
    {
        path: ADMIN_DIRECTION_EDIT_PAGE,
        Component: DirectionEdit
    }
]