import React from 'react'
import img1 from "../../images/1.png"

const SampleCard = ({card, siteType, handleSelect}) => {
  return (
    <div className={siteType === card.value ? 'sample-types-item active' : 'sample-types-item'} 
        onClick={() => handleSelect(card.value)}
    >
        <img src={card.img} alt="" />
        <h3>{card.title}</h3>
        <p>Удобный интерфейс, который поможет вам привлечь клиентов и повысить уровень продаж.</p>
    </div>
  )
}

export default SampleCard