import React, { useState } from 'react'
import img1 from "../../images/1.png"
import img2 from "../../images/2.png"
import img3 from "../../images/3.png"
import img4 from "../../images/4.png"
import img5 from "../../images/5.png"
import img6 from "../../images/6.png"
import img7 from "../../images/7.png"
import img8 from "../../images/8.png"
import img9 from "../../images/9.png"
import img10 from "../../images/10.png"
import img11 from "../../images/11.png"
import img12 from "../../images/12.png"
import SampleCard from '../simple/SampleCard'

const SampleType = ({
    setConstructorItem, 
    siteType,
    setSiteType
}) => {
    const [error, setError] = useState();

    const handleNext = () => {
        if(siteType){
            setConstructorItem(2)
        }else{
            setError(true)
        }
    }

    const handleSelect = (value) => {
        setSiteType(value);
        setError(false);
    }

    const sitetypes = [
        {id: 1, title: 'Доставка еды', value: 'food', img: img1},
        {id: 1, title: 'Супермаркеты и магазины', value: 'stores', img: img2},
        {id: 1, title: 'Кофейни', value: 'coffee', img: img3},
        {id: 1, title: 'Доставка цветов', value: 'flowers', img: img4},
        {id: 1, title: 'Детские товары, игрушки, хобби', value: 'kids', img: img12},
        {id: 1, title: 'Beauty сфера', value: 'beauty', img: img6},
        {id: 1, title: 'Одежда, обувь, аксессуары', value: 'dresses', img: img7},
        {id: 1, title: 'Лекарства и медицина', value: 'medicine', img: img8},
        {id: 1, title: 'Зоомагазины', value: 'pet_stores', img: img9},
        {id: 1, title: 'Промышленные и строительные материалы', value: 'construction_materials', img: img10},
        {id: 1, title: 'Мебель', value: 'furniture', img: img11},
    ]
    
  return (
    <>
        <div>
            <h3>Какое направление у вашего бизнеса?</h3>
            <p>Пожалуйста выберите вашу сферу деятельности, чтобы подобрать лучшее решения для вас</p>
        </div>
        <div>
            <div className='sample-types'>
                {sitetypes?.map((card, index) =>
                    <SampleCard card={card} key={index} siteType={siteType} handleSelect={handleSelect}/>
                )}
            </div>
            {error && <div className='error-text'>Пожалуйста, укажите направление вашего бизнеса.</div>}
        </div>
        <div className='main-btn mx-wd' onClick={handleNext}>Далее</div>
    </>
  )
}

export default SampleType