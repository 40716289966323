import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful';

const ColorModal = ({show, setShow, colors, setColors}) => {
    const [color, setColor] = useState('');

    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(false);
    }

    const handlePrev = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleSave = () => {
        if(color){
            setColors([...colors, {id: Math.random().toString(16), value: color}]);
            setShow(false)
        }
    }

  return (
    <div className={show ? 'main-modal-wrapper show' : 'main-modal-wrapper'} onClick={handleClose}>
        <div className='main-modal' onClick={handlePrev}>
            <div className='close-modal' onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#1C1B1F"/>
                </svg>
            </div>
            <div className='mb-15'>
                <HexColorPicker color={color} onChange={setColor} />
            </div>
            <input type="text" className='input mb-15' placeholder='Цвет' value={color} onChange={e => setColor(e.target.value)}/>
            <div className='main-btn' onClick={handleSave}>Сохранить</div>
        </div>
    </div>
  )
}

export default ColorModal