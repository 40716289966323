import React from 'react'
import PageWrapper from '../../../components/complex/PageWrapper'
import { NavLink } from 'react-router-dom'

const SiteDesignCreate = () => {
  return (
    <PageWrapper>
        <div className='header-links'>
            <NavLink to={'/admin/site-design'}>
                Все направления
            </NavLink>
            <span>/</span>
            <div>Создание</div>
        </div>
        <div className='page-container'>
            <h1>Создать направления</h1>
            <div>
                <label>Название</label>
                <input type="text" className='input' placeholder='Название'/>
            </div>
            <div>
                <label>Описание</label>
                <textarea className='textarea' placeholder='Описание'/>
            </div>
            <div>
                <img src="" alt="" />
            </div>
            <div className='buttons-line'>
                <button className='main-btn mx-wd-190'>Сохранить</button>
                <div className='border-btn mx-wd-190'>Удалить</div>
            </div>
        </div>
    </PageWrapper>
  )
}

export default SiteDesignCreate