import React from 'react'
import { NavLink } from 'react-router-dom'
import PageWrapper from '../../../components/complex/PageWrapper'
import TableTr from '../../../components/simple/TableTr'

const Direction = () => {
  return (
    <PageWrapper>
        <div className='btns-flex mb-30'>
            <h1>Направления бизнеса</h1>
            <NavLink className='main-btn mx-wd-190' to={'/admin/direction/create'}>
                Создать
            </NavLink>
        </div>
        <div className='table'>
            <div className='table-tr tr-4'>
                <div>Фото</div>
                <div>Название</div>
                <div>Дизайн</div>
            </div>
            <TableTr/>
            <TableTr/>
            <TableTr/>
            <TableTr/>
            <TableTr/>
        </div>        
    </PageWrapper>
  )
}

export default Direction