import React from 'react'

const Loading = () => {
  return (
    <div className='loading-wrapper'>
        <div className="loader"></div>
        <h3>Мы создаем ваш сайт</h3>
        <p>Это займет 1-2 минуты</p>
    </div>
  )
}

export default Loading