import React from 'react'
import PageWrapper from '../../../components/complex/PageWrapper'
import { NavLink } from 'react-router-dom'

const DirectionCreate = () => {
  return (
    <PageWrapper>
        <div className='header-links'>
            <NavLink to={'/admin/direction'}>
                Все направления
            </NavLink>
            <span>/</span>
            <div>Создание</div>
        </div>
        <div className='page-container'>
            <h1>Создать направления</h1>
            <div className='file-upload'>
                <input type="file"  />
                <div className='file-upload-content'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="32" viewBox="0 0 35 32" fill="none">
                    <path d="M17.5 23.4213L6.875 13.9147L9.85 11.1578L15.375 16.1012V0.605469H19.625V16.1012L25.15 11.1578L28.125 13.9147L17.5 23.4213ZM4.75 31.0265C3.58125 31.0265 2.58073 30.6542 1.74844 29.9095C0.916146 29.1648 0.5 28.2696 0.5 27.2239V21.5199H4.75V27.2239H30.25V21.5199H34.5V27.2239C34.5 28.2696 34.0839 29.1648 33.2516 29.9095C32.4193 30.6542 31.4188 31.0265 30.25 31.0265H4.75Z" fill="#090909"/>
                    </svg>
                    Загрузить
                </div>
            </div>
            <div>
                <label>Название</label>
                <input type="text" className='input' placeholder='Название'/>
            </div>
            <div>
                <label>Описание</label>
                <textarea className='textarea' placeholder='Описание'/>
            </div>
            <div className='buttons-line'>
                <button className='main-btn mx-wd-190'>Сохранить</button>
            </div>
        </div>
    </PageWrapper>
  )
}

export default DirectionCreate