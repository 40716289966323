import React from 'react'
import "./App.scss"
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './navigation/AppRouter'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <BrowserRouter>
        <AppRouter/>
    </BrowserRouter>
  )
}

export default App