export const PROJECTS_ROUTE = '/projects'
export const BLOG_ROUTE = '/articles'
export const SERVICES_ROUTE = '/services'
export const EVENTS_ROUTE = '/events'


export const MAIN_PAGE_ROUTE = '/:lang/*'

export const ADMIN_PAGE = '/admin'
export const ADMIN_DIRECTION_PAGE = '/admin/direction'
export const ADMIN_DIRECTION_CREATE_PAGE = '/admin/direction/create'
export const ADMIN_DIRECTION_EDIT_PAGE = '/admin/direction/:id/edit'

export const ADMIN_DESIGN_PAGE = '/admin/site-design'
export const ADMIN_DESIGN_CREATE_PAGE = '/admin/site-design/create'