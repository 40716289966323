import React from 'react'

const DeleteModal = ({show, setShow}) => {

    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(false);
    }

    const handlePrev = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleDelete = () => {
        setShow(false)
    }
    
  return (
    <div className={show ? 'main-modal-wrapper show' : 'main-modal-wrapper'} onClick={handleClose}>
        <div className='main-modal' onClick={handlePrev}>
            <div className='close-modal' onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#1C1B1F"/>
                </svg>
            </div>
            <h3>Вы уверены, что хотите удалить запись?</h3>
            <p className='mt-20'>Данные удалятся без возможности восстановления</p>
            <div className='buttons-line mt-20'>
                <button className='main-btn mx-wd-190' onClick={() => setShow(false)}>Не удалять</button>
                <div className='border-btn mx-wd-190' onClick={handleDelete}>Удалить</div>
            </div>
        </div>
    </div>
  )
}

export default DeleteModal