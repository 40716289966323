import React from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/1.png"

const TableTr = () => {
  return (
    <div className='table-tr tr-4'>
        <div>
            <img src={img} alt="" />
        </div>
        <div>Доставка еды</div>
        <div>Light, Market</div>
        <div className='btns-flex'>
            <NavLink to={'/admin/direction/1/edit'} className='icon-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M2 16.5H3.425L13.2 6.725L11.775 5.3L2 15.075V16.5ZM0 18.5V14.25L13.2 1.075C13.4 0.891667 13.6208 0.75 13.8625 0.65C14.1042 0.55 14.3583 0.5 14.625 0.5C14.8917 0.5 15.15 0.55 15.4 0.65C15.65 0.75 15.8667 0.9 16.05 1.1L17.425 2.5C17.625 2.68333 17.7708 2.9 17.8625 3.15C17.9542 3.4 18 3.65 18 3.9C18 4.16667 17.9542 4.42083 17.8625 4.6625C17.7708 4.90417 17.625 5.125 17.425 5.325L4.25 18.5H0ZM12.475 6.025L11.775 5.3L13.2 6.725L12.475 6.025Z" fill="#1C1B1F"/>
                </svg>
            </NavLink>
            <div className='icon-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <mask id="mask0_492_5219" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                    <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_492_5219)">
                    <path d="M9.4 17L12 14.4L14.6 17L16 15.6L13.4 13L16 10.4L14.6 9L12 11.6L9.4 9L8 10.4L10.6 13L8 15.6L9.4 17ZM7 21.5C6.45 21.5 5.97917 21.3042 5.5875 20.9125C5.19583 20.5208 5 20.05 5 19.5V6.5H4V4.5H9V3.5H15V4.5H20V6.5H19V19.5C19 20.05 18.8042 20.5208 18.4125 20.9125C18.0208 21.3042 17.55 21.5 17 21.5H7ZM17 6.5H7V19.5H17V6.5Z" fill="#1C1B1F"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
  )
}

export default TableTr