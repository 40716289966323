import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from "../../images/logo.png" 

const PageWrapper = ({children}) => {
  return (
    <div>
        <div className='header'>
            <img src={logo} alt="" />
        </div>
        <div className='page-grid'>
            <div className='sidemenu'>
                <NavLink to={'/admin/direction'} className={'nav-link'}>Направления</NavLink>
                <NavLink to={'/admin/site-design'} className={'nav-link'}>Дизайн сайта</NavLink>
            </div>
            <div className='page-wrapper'>
                {children}
            </div>
        </div>
    </div>
  )
}

export default PageWrapper