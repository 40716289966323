import React, { useState } from 'react'
import Slider from "react-slick";
import img from "../../images/ima.png"

const Modal = ({show, setShow}) => {

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(false);
    }

    const handlePrev = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }
    
  return (
    <div className={show ? 'main-modal-wrapper show' : 'main-modal-wrapper'} onClick={handleClose}>
        <div className='main-modal' onClick={handlePrev}>
            <div className='close-modal' onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#1C1B1F"/>
                </svg>
            </div>
            <Slider {...settings}>
                <div>
                    <img src={img} alt="" />
                </div>
                <div>
                <img src={img} alt="" />
                </div>
                <div>
                <img src={img} alt="" />
                </div>
                <div>
                <img src={img} alt="" />
                </div>
                <div>
                <img src={img} alt="" />
                </div>
                <div>
                <img src={img} alt="" />
                </div>
            </Slider>
            <h3>Баннер с вашим УТП</h3>
            <p>Возможность разместить несколько баннеров в слайдере</p>
        </div>
    </div>
  )
}

export default Modal