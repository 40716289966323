import React from 'react'
import PageWrapper from '../../../components/complex/PageWrapper'
import { NavLink } from 'react-router-dom'
import SiteTr from '../../../components/simple/SiteTr'

const SiteDesign = () => {
  return (
    <PageWrapper>
        <div className='btns-flex mb-30'>
            <h1>Дизайн сайта</h1>
            <NavLink className='main-btn mx-wd-190' to={'/admin/site-design/create'}>
                Создать
            </NavLink>
        </div>
        <div className='table'>
            <div className='table-tr tr-4-min'>
                <div>Фото</div>
                <div>Название</div>
                <div>Описание</div>
            </div>
            <SiteTr/>
            <SiteTr/>
            <SiteTr/>
            <SiteTr/>
            <SiteTr/>
        </div>  
    </PageWrapper>
  )
}

export default SiteDesign